import { useEffect, useState } from 'react'

export const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= 640)
    }

    handleResize() // Set initial value
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}
