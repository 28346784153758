import React, { useCallback, useEffect } from 'react'
import { ErrorLoadProjects, FormInput, Icon } from 'interface'
import { t } from '@lingui/macro'
import { useRouter } from 'next/router'
import { usePageVisibility } from '../../../hooks/misc/use-page-visibility'
import { useTasks } from '../../../hooks/tasks/use-tasks'
import { Portal } from '../../portal'
import { ProjectsList } from '../projects-list'
import { trigger } from '../../../lib/events'
import { useEvent } from '../../../hooks/analytics'

export const ProjectsPage: React.FC = () => {
  const { sendEvent } = useEvent()
  const router = useRouter()
  const pageIsActive = usePageVisibility()
  const {
    searchTasks: {
      value: valueSearch,
      onClear: onClearSearch,
      onChange: onChangeSearch
    },
    tasks: { loaderRef, loading, error, tracks, totalCount },
    isTasksLoaded
  } = useTasks({
    limit: 100,
    disabled: !pageIsActive
  })

  useEffect(() => {
    if (isTasksLoaded) {
      if (!loading && tracks.length === 0 && router.pathname === '/') {
        router.push(`/models`)
      }

      sendEvent({
        name: 'voice-studio-projects-viewed',
        category: 'engagement',
        customAttributes: {
          number_of_projects: tracks.length
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTasksLoaded])

  const handleRefresh = useCallback(() => trigger('revalidate:tasks'), [])

  if (error && !tracks.length) {
    return <ErrorLoadProjects onClickAction={handleRefresh} />
  }

  return (
    <>
      <Portal id="page-wrapper-input">
        <div className="relative rounded-md shadow-sm">
          <FormInput
            id="search_field_searchbox_1"
            type="text"
            value={valueSearch}
            disabled={loading}
            icon={<Icon name="search" />}
            onChange={onChangeSearch}
            onClear={onClearSearch}
            placeholder={t`Search`}
          />
        </div>
      </Portal>

      <ProjectsList
        loading={loading}
        tracks={tracks}
        totalCount={totalCount}
        valueSearch={valueSearch}
      />

      {/* ref bottom page for pagination */}
      {!loading && tracks.length > 0 && loaderRef && <span ref={loaderRef} />}
    </>
  )
}
