import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInterval, useList } from 'react-use'
import { Task } from '../../../types/task.types'
import { UseSearchTasks, useSearchTasks } from '../use-search-tasks'
import { useFetchTasks } from '../use-fetch-tasks'
import { off, on } from '../../../lib/events'

interface UseTask {
  loaderRef: any
  totalCount: number
  tracks: Task[]
  error: boolean
  loading: boolean
  mutateTasks(): void
}

export interface UseTasks {
  tasks: UseTask
  searchTasks: UseSearchTasks
  isTasksLoaded: boolean
}

export interface UseTasksProps {
  limit?: number
  disabled?: boolean
}

export const useTasks = ({
  disabled = true,
  limit: qtdItems
}: UseTasksProps): UseTasks => {
  const searchTasks = useSearchTasks()
  const { fetchTasks, totalCount, error } = useFetchTasks()
  const [isTasksLoaded, setIsTasksLoaded] = useState(false)

  const disableFetchingRef = useRef(false)
  useEffect(() => {
    disableFetchingRef.current = disabled
  }, [disabled])

  const [QTD_ITEMS] = useState(qtdItems || 15)
  const [
    tracks,
    {
      set: setListTasks,
      push: pushTask,
      removeAt: removeTask,
      clear: clearTasks
    }
  ] = useList<Task>([])

  const offset = useRef<number>(0)
  const limit = useRef<number>(QTD_ITEMS)
  const [loading, setLoading] = useState(false)
  const disableObserverRef = useRef<boolean>(false)
  const mutateLoadingRef = useRef<boolean>(false)
  const listOperationsName = useMemo(
    () => ['VOICETRANSFER_A', 'VOICECONVERSION_A'],
    []
  )

  const searchQuery = useMemo(
    () => searchTasks.filterTrackByText,
    [searchTasks.filterTrackByText]
  )

  const mutateTasks = useCallback(async () => {
    if (mutateLoadingRef.current) {
      return
    }

    mutateLoadingRef.current = true
    const config = {
      offset: 0,
      limit: tracks.length,
      searchQuery,
      operationName: listOperationsName
    }
    const list: Task[] = await fetchTasks(config)

    // update state tracks
    if (list) {
      setListTasks(list)
    }

    mutateLoadingRef.current = false
  }, [tracks, searchQuery, listOperationsName, fetchTasks, setListTasks])

  const removeTaskFromList = useCallback(
    (data: any) => {
      const { taskId } = data.detail
      if (taskId) {
        const trackIndex = tracks.findIndex((i: Task) => i.id === taskId)
        if (trackIndex >= 0) {
          removeTask(trackIndex)
          mutateTasks()
        }
      }
    },
    [tracks, removeTask, mutateTasks]
  )

  const fetchData = useCallback(
    async ({ first, reset }: { first?: boolean; reset?: boolean }) => {
      if (
        !first &&
        (disableObserverRef.current || disableFetchingRef.current)
      ) {
        return
      }

      setIsTasksLoaded(false)
      setLoading(true)

      if (reset) {
        offset.current = 0
        limit.current = QTD_ITEMS
      }

      const config = {
        offset: offset.current,
        limit: limit.current,
        searchQuery,
        operationName: listOperationsName
      }

      if (!first) {
        config.offset += QTD_ITEMS
        offset.current = config.offset
      }

      const list: Task[] = await fetchTasks(config)

      if (list?.length) {
        pushTask(...list)
      }

      setLoading(false)
      setIsTasksLoaded(true)
    },
    [QTD_ITEMS, searchQuery, listOperationsName, fetchTasks, pushTask]
  )

  const loaderRef = useCallback(
    (node: any) => {
      if (node !== null) {
        const observer = new IntersectionObserver(
          (entities: any) => {
            const target = entities[0]

            if (target.isIntersecting) {
              fetchData({})
            }
          },
          {
            root: null,
            rootMargin: '200px',
            threshold: 1.0
          }
        )
        observer.observe(node)
      }
    },
    [fetchData]
  )

  const disableFetching = useMemo(
    () =>
      !tracks.some((i) =>
        i.operations.some(
          (x) => x.status === 'STARTED' || x.status === 'QUEUED'
        )
      ),
    [tracks]
  )

  useInterval(
    () => {
      mutateTasks()
    },
    disabled || disableFetching ? null : 10000
  )

  useEffect(() => {
    if (totalCount === 0) {
      return
    }

    if (tracks.length >= totalCount) {
      disableObserverRef.current = true
    }
  }, [totalCount, tracks])

  useEffect(() => {
    const firstFetch = async (params: any): Promise<void> => {
      await fetchData(params)
      disableObserverRef.current = false
    }
    disableObserverRef.current = true
    clearTasks()
    firstFetch({ reset: true, first: true })
  }, [fetchData, clearTasks])

  useEffect(() => {
    on('revalidate:tasks', mutateTasks)
    on('revalidate:task-deleted', removeTaskFromList)

    return () => {
      off('revalidate:tasks', mutateTasks)
      off('revalidate:task-deleted', removeTaskFromList)
    }
  }, [mutateTasks, removeTaskFromList])

  return {
    searchTasks,
    tasks: {
      mutateTasks,
      loaderRef: loading ? null : loaderRef,
      totalCount: totalCount || 0,
      tracks,
      loading,
      error
    },
    isTasksLoaded
  }
}
