import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Icon, Loading } from 'interface'
import { Trans } from '@lingui/macro'
import { Button } from 'interface/src/components/button'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { useUserStore } from '../../store/user'
import { useModelStore } from '../../store/models'
import { useEvent } from '../../hooks/analytics'
import { ModalUnlimitedUploads } from '../../components/modal-unlimited-uploads'
import { useIsMobile } from '../../hooks/misc/use-is-mobile'
import { UnsupportedDevice } from '../../components/unsupported-device'

interface Props {
  children: React.ReactNode
}

export const PageWrapper: React.FC<Props> = ({ children }) => {
  const { push, replace, asPath, pathname, query } = useRouter()
  const { user, userToken, loadUserData } = useUserStore()
  const { models } = useModelStore()
  const { sendEvent } = useEvent()
  const isMobile = useIsMobile()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const onOpenModal = useCallback(() => {
    setIsOpenModal(true)
  }, [])
  const onCloseModal = useCallback(() => {
    setIsOpenModal(false)
  }, [])

  const availableCredits = useMemo(
    () => user?.subscription?.availableCredits || 0,
    [user]
  )

  const hasAvailableCredits = useMemo(
    () => availableCredits > 0,
    [availableCredits]
  )

  useEffect(() => {
    if (query?.noCreditsLeft) {
      replace('/projects/', undefined, { shallow: true })
      onOpenModal()
    }
  }, [query, replace, onOpenModal])

  const onGoUploadPage = useCallback(() => {
    if (hasAvailableCredits) {
      sendEvent({
        name: 'voice-studio-convert-voice-clicked',
        category: 'engagement'
      })
      return push('/upload')
    }

    return onOpenModal()
  }, [push, onOpenModal, sendEvent, hasAvailableCredits])

  const hasPrivateModel = useMemo(() => {
    const privateModels = models.filter((item) => item.private === true)

    return privateModels.length > 0
  }, [models])

  const tabs = useMemo(
    () => [
      {
        id: 'projects',
        title: <Trans>Projects</Trans>,
        active: pathname === '/' || pathname === '/projects',
        onClick: () => push('/projects'),
        disabled: false
      },
      {
        id: 'voices',
        title: <Trans>Voices</Trans>,
        active: asPath === '/models/',
        onClick: () => push('/models'),
        disabled: false
      },
      {
        id: 'private-voices',
        title: <Trans>Private Models</Trans>,
        active: asPath === '/models/?private=true',
        onClick: () => push('/models?private=true'),
        disabled: !hasPrivateModel
      }
    ],
    [push, asPath, pathname, hasPrivateModel]
  )

  const enabledTabs = useMemo(() => tabs.filter((tab) => !tab.disabled), [tabs])

  useEffect(() => {
    const run = async (): Promise<void> => {
      if (userToken) {
        await loadUserData(userToken, true)
      }
    }
    run()
    // eslint-disable-next-line
  }, [])

  if (!user) {
    return <Loading className="mt-48 mx-auto" />
  }

  if (isMobile) {
    return <UnsupportedDevice />
  }

  return (
    <div className="py-8 px-6 max-w-[1140px] mx-auto">
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2
            className="font-semibold leading-7 text-white sm:text-ellipsis  sm:tracking-tight"
            style={{
              fontSize: '2.125rem'
            }}
          >
            <Trans>Voice Studio</Trans>
          </h2>
        </div>

        <div className="mt-4 flex flex-col md:flex-row md:ml-4 md:mt-0 gap-3">
          <div id="page-wrapper-input" className="md:w-60" />

          <Button onClick={onGoUploadPage}>
            <Icon name="plus" />
            <Trans>Convert Voice</Trans>
          </Button>
        </div>
      </div>

      <div className="border-b border-gray-200 mt-6 mb-8">
        <div className="block">
          <nav className="-mb-px flex" data-total={enabledTabs.length}>
            {enabledTabs.map((item) => (
              <button
                id={item.id}
                type="button"
                key={item.id}
                onClick={item.onClick}
                className={classNames(
                  item.active
                    ? 'border-white-500 text-white-600'
                    : 'border-transparent text-white-50A hover:border-gray-300 hover:text-white-70A',
                  'whitespace-nowrap border-b-2 px-5 py-4 text-sm font-medium'
                )}
              >
                {item.title}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div>{children}</div>

      {isOpenModal && (
        <ModalUnlimitedUploads open={isOpenModal} onDismiss={onCloseModal} />
      )}
    </div>
  )
}
