import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  EmptyProjects,
  Loading,
  ProjectListAvatar,
  ProjectListHeader,
  ProjectListItem
} from 'interface'
import { useRouter } from 'next/router'
import { ProjectNotFound } from 'interface/src/components/empty-state/project-not-found'
import { useModelStore } from '../../../store/models'
import { Task, TaskOperations } from '../../../types/task.types'
import { formatSeconds, getFileMetadata } from '../../../lib/misc/utils'
import { getProcessedVoices } from '../../project/project-page/utils'
import { DropDownProjectList } from '../../../components/drop-down-project-list'
import { useUserStore } from '../../../store/user'
import { useEvent } from '../../../hooks/analytics'
import { ModalUnlimitedUploads } from '../../../components/modal-unlimited-uploads'

interface Props {
  loading: boolean
  tracks: Task[]
  totalCount: number
  valueSearch: string
}

export const ProjectsList: React.FC<Props> = ({
  loading: _loading,
  tracks,
  totalCount,
  valueSearch
}) => {
  const { push } = useRouter()
  const { models } = useModelStore()
  const { user } = useUserStore()
  const { sendEvent } = useEvent()

  // we need to set loading true on first load
  // to not show the empty state until
  // the tracks is loaded
  const [loading, setLoading] = useState(true)
  useEffect(() => setLoading(_loading), [_loading])

  const [isOpenModal, setIsOpenModal] = useState(false)
  const onOpenModal = useCallback(() => {
    setIsOpenModal(true)
  }, [])
  const onCloseModal = useCallback(() => {
    setIsOpenModal(false)
  }, [])

  const projectsNotFound = useMemo(
    () => !loading && tracks.length === 0 && valueSearch,
    [loading, tracks, valueSearch]
  )

  const empty = useMemo(
    () => !loading && tracks.length === 0 && !valueSearch,
    [loading, tracks, valueSearch]
  )

  const availableCredits = useMemo(
    () => user?.subscription?.availableCredits || 0,
    [user]
  )

  const hasAvailableCredits = useMemo(
    () => availableCredits > 0,
    [availableCredits]
  )

  const onGoUploadPage = useCallback(() => {
    if (hasAvailableCredits) {
      return push('/upload')
    }

    return onOpenModal()
  }, [push, hasAvailableCredits, onOpenModal])

  const getModelsList = useCallback(
    (operations: TaskOperations[]) => getProcessedVoices(operations, models),
    [models]
  )

  const getDuration = useCallback((operations: TaskOperations[]) => {
    return formatSeconds(getFileMetadata(operations)?.duration)
  }, [])

  useEffect(() => {
    if (!loading && valueSearch) {
      sendEvent({
        name: 'voice-studio-projects-searched',
        category: 'engagement',
        customAttributes: {
          number_of_results: tracks.length
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <>
      {loading ? (
        <Loading className="mx-auto" />
      ) : projectsNotFound ? (
        <ProjectNotFound search={valueSearch} />
      ) : empty ? (
        <EmptyProjects onClickAction={onGoUploadPage} />
      ) : tracks.length > 0 ? (
        <>
          <ProjectListHeader projectCount={totalCount} />

          <div className="flex flex-col gap-2 mt-2">
            {tracks.map((track) => {
              const listOfModels = getModelsList(track.operations)

              const operations = track?.operations?.filter(({ name }) =>
                ['VOICETRANSFER_A'].includes(name)
              )

              const haveOperations = operations.length > 0
              return (
                <ProjectListItem
                  key={track.id}
                  name={track.file.name}
                  duration={getDuration(track.operations)}
                  onListItemClick={() => push(`/project/${track.id}`)}
                  Avatar={
                    haveOperations ? (
                      <ProjectListAvatar list={listOfModels} />
                    ) : (
                      <div>
                        <p>-</p>
                      </div>
                    )
                  }
                  actionDropdown={
                    <DropDownProjectList id={track.id} name={track.file.name} />
                  }
                />
              )
            })}
          </div>
        </>
      ) : null}
      {isOpenModal && (
        <ModalUnlimitedUploads open={isOpenModal} onDismiss={onCloseModal} />
      )}
    </>
  )
}
