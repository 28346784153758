import { useState } from 'react'
import { useAsyncFn } from 'react-use'
import * as Sentry from '@sentry/nextjs'
import { config } from '../../../config'
import { Task } from '../../../types/task.types'
import { Moises } from '../../../lib/graphql'
import { useUserStore } from '../../../store/user'

interface UseFetchTasks {
  error: boolean
  loading: boolean
  totalCount: number
  fetchTasks(i: UseFetchProps): Promise<Task[]>
}

interface UseFetchProps {
  offset: number
  limit: number
  searchQuery?: string
  operationName?: string[]
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useFetchTasks = (): UseFetchTasks => {
  const { userToken } = useUserStore()
  const [totalCount, setTotalCount] = useState(0)

  const [state, fetchTasks] = useAsyncFn(
    async ({
      offset,
      limit,
      searchQuery = '',
      operationName
    }: UseFetchProps): Promise<Task[]> => {
      if (!userToken) {
        return []
      }

      MoisesCLI.auth(userToken)
      let result = []

      try {
        const { tracks } = await MoisesCLI.fetchTasks({
          offset,
          limit,
          searchQuery,
          operationName,
          hideDemos: true // referer abtest moises collection
        })

        if (tracks) {
          result =
            (tracks && tracks.edges.map((i: { node: Task }) => i.node)) || []
          setTotalCount(tracks.totalCount)
        }
      } catch (e: any) {
        Sentry.captureMessage('Error fetching tasks', {
          level: 'error',
          extra: {
            isAuthFailed: e?.response?.errors?.some(
              (i: any) => i?.extensions?.code === 'UNAUTHENTICATED'
            ),
            codes: e?.response?.errors
              ?.map((i: any) => i?.extensions?.code)
              ?.join(', '),
            error: e
          }
        })
        throw new Error(e)
      }

      return result
    },
    [userToken]
  )

  return {
    error: !!state.error,
    loading: state.loading,
    fetchTasks,
    totalCount
  }
}
